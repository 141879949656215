import type { UserType } from '../../types/user';
import { createSlice } from '@reduxjs/toolkit';

const initialState: UserType = {
  username: '',
  email: '',
  firstName: '',
  lastName: '',
  emailVerified: false,
  phoneNumberVerified: false,
  phoneNumber: '',
  hasAddress: false,
  termsAccepted: false,
  currentUserCart: '',
  groups: [],
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
    clearUser() {
      return initialState;
    },
  },
});

export const { setUser, clearUser } = userSlice.actions;

export default userSlice.reducer;
