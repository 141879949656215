import type { OrderType } from '../../types/orders';
import { createSlice } from '@reduxjs/toolkit';

const initialState: OrderType = {
  id: 0,
  createdAt: '',
  reference: '',
  isCart: false,
  status: null,
  orderType: 'delivery',
  storeAddress: null,
  deliveryAddress: null,
  deliveryDatetime: null,
  orderCost: null,
  orderReceipts: [],
  client: null,
  branchStores: [],
  productsCount: 0,
  codeToDeliver: '',
  shapyer: '',
  deliveredBy: null,
  promotionalCode: null,
  orderCategory: 'store'
};

const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    setCart(state, { payload }) {
      if (!state.orderCost) {
        return payload;
      }

      if (state.orderCost.deliveryCost > 0) {
        const orderCost = {
          ...payload.orderCost,
          deliveryCost: state.orderCost.deliveryCost,
        };

        state = {
          ...payload,
          orderCost,
        };

        return state;
      }

      return payload;
    },
    updateDeliveryCost(state, { payload }) {
      if (!state.orderCost) {
        return state;
      }

      const orderCost = {
        ...state.orderCost,
        deliveryCost: payload,
      };

      state = {
        ...state,
        orderCost,
      };

      return state;
    },
    cleanCart() {
      return initialState;
    },
  },
});

export const {
  setCart,
  cleanCart,
  updateDeliveryCost,
} = cartSlice.actions;

export default cartSlice.reducer;
