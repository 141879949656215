import type { ProductsListType } from '../../types/product';
import { createSlice } from '@reduxjs/toolkit';

const initialState: ProductsListType = {
  count: 0,
  next: null,
  previous: null,
  results: [],
  perPage: 25,
  totalPages: 1,
};

const productsSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    setProducts(_, { payload }) {
      return payload;
    },
  },
});

export const { setProducts } = productsSlice.actions;

export default productsSlice.reducer;
