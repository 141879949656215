import { createSlice } from '@reduxjs/toolkit';
import { uiType } from '../../types/ui';

const initialState: uiType = {
  name: '',
  slug: '',
  domain: '',
  storeId: 0,
  businessId: 0,
  mainLogo: {
    data: null,
  },
  secondaryLogo: {
    data: null,
  },
  mobileLogo: {
    data: null,
  },
  storeBackground: {
    data: null,
  },
  metatags: {
    title: '',
    description: '',
    twitter: '',
    openGraph: {},
    favicon: {},
  },
  pallette: {
    primaryColor: '',
    secondaryColor: '',
    fontColor: '',
    backgroundColor: '',
    footerBackgroundColor: '',
    footerTextColor: '',
    breakpoints: {
      mobile: '',
      mobileL: '',
      tablet: '',
      tabletL: '',
      laptop: '',
      laptopM: '',
      laptopL: '',
      tabletSmax: '',
      laptopSmax: '',
      laptopMmax: '',
      laptopLmax: '',
    },
    lightGray: '',
    middleGray: '',
    darkGray: '',
    gray2: '',
    gray3: '',
    gray4: '',
    gray5: '',
    gray6: '',
    gray7: '',
    gray8: '',
    gray9: '',
    gray10: '',
    gray11: '',
    red1: '',
    white: '',
    black: '',
    orderDeliveryTypeColor: '',
    orderPickupTypeColor: '',
    nonProductActiveColor: '',
  },
};

const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    setTheme(state, { payload }) {
      return {
        ...state,
        activeTheme: payload,
      };
    },
  },
});

export const { setTheme } = uiSlice.actions;

export default uiSlice.reducer;
