import type { BranchStoreType } from '../../types/branchStore';
import { createSlice } from '@reduxjs/toolkit';

const initialState: BranchStoreType = {
  id: 0,
  name: '',
  isHq: false,
  hasPickup: false,
  hasDelivery: false,
  videos: [],
  socialMedia: [],
  store: {
    name: '',
    description: '',
    logo: '',
    storeType: {
      id: 0,
      name: '',
      highlighted: false,
      logo: '',
      color: '',
      storeCategory: {
        id: 0,
        name: '',
        productCount: 0,
      }
    },
    storeCategory: {
      id: 0,
      name: '',
      productCount: 0,
    }
  },
  address: {
    id: 0,
    address: '',
    description: '',
    city: '',
    state: '',
    zipcode: '',
    coordLat: '',
    coordLong: ''
  },
  paymentProcessor: {
    name: 'stripe',
    config: {
      locationId: '',
      userId: '',
      apiKey: '',
      developerId: '',
    },
  },
  isClosed: false,
  deliveryCost: 0,
  commission: 0
};

const branchStoreSlice = createSlice({
  name: 'branchStore',
  initialState,
  reducers: {
    setBranchStore(_, { payload }) {
      return payload;
    },
  },
});

export const { setBranchStore } = branchStoreSlice.actions;

export default branchStoreSlice.reducer;
