export const size = {
  sm: '640px',
  md: '768px',
  lgs: '1023px',
  lg: '1024px',
  xls: '1279px',
  xl: '1280px',
  xxl: '1536px',
  xxlm: '1600px',
  xxlmm: '1601px',
  xxxls: '1750px',
  xxxl: '2560px',
};

export const breakpoints = {
  mobile: `only screen and (min-width: ${size.sm})`,
  mobileL: `only screen and (min-width: ${size.md})`,
  tablet: `only screen and (min-width: ${size.lg})`,
  tabletL: `only screen and (min-width: ${size.xl})`,
  laptop: `only screen and (min-width: ${size.xxl})`,
  laptopM: `only screen and (min-width: ${size.xxlmm})`,
  laptopL: `only screen and (min-width: ${size.xxxl})`,
  tabletSmax: `only screen and (max-width: ${size.lgs})`,
  laptopSmax: `only screen and (max-width: ${size.xls})`,
  laptopMmax: `only screen and (max-width: ${size.xxlm})`,
  laptopLmax: `only screen and (max-width: ${size.xxxls})`,
};
