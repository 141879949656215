import type { BranchStoreScheduleType } from '../../types/branchStoreSchedule';
import { createSlice } from '@reduxjs/toolkit';

const initialState: BranchStoreScheduleType = {
  count: 0,
  next: null,
  previous: null,
  results: {
    id: 0,
    day: '',
    fromTime: '',
    toTime: ''
  }
};

const branchStoreScheduleSlice = createSlice({
  name: 'branchStoreSchedule',
  initialState,
  reducers: {
    setBranchStoreSchedule(_, { payload }) {
      return payload;
    },
  },
});

export const { setBranchStoreSchedule } = branchStoreScheduleSlice.actions;

export default branchStoreScheduleSlice.reducer;
