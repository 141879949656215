import type { CategoryType } from '../../types/category';
import { createSlice } from '@reduxjs/toolkit';

const initialState: Array<CategoryType> = [];

const categoriesSlice = createSlice({
  name: 'categories',
  initialState,
  reducers: {
    setCategories(_, { payload }) {
      return payload;;
    },
  },
});

export const { setCategories } = categoriesSlice.actions;

export default categoriesSlice.reducer;
