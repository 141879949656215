import { breakpoints } from './breakpoints';
import type { pallette as palletteType } from '../types/theme/pallette';

const defaultPallette = {
  breakpoints,
  primaryColor: '',
  secondaryColor: '',
  fontColor: '',
  backgroundColor: '',
  footerBackgroundColor: '',
  footerTextColor: '',
  lightGray: '',
  middleGray: '',
  darkGray: '',
  gray2: '',
  gray3: '',
  gray4: '',
  gray5: '',
  gray6: '',
  gray7: '',
  gray8: '',
  gray9: '',
  gray10: '',
  gray11: '',
  red1: '',
  white: '',
  black: '',
  orderDeliveryTypeColor: '',
  orderPickupTypeColor: '',
  nonProductActiveColor: '',
};

export const themeConfig = (theme: palletteType = defaultPallette) => {
  return {
    breakpoints: breakpoints,
    primaryColor: theme.primaryColor,
    secondaryColor: theme.secondaryColor,
    fontColor: theme.fontColor,
    backgroundColor: theme.backgroundColor,
    footerBackgroundColor: theme.footerBackgroundColor,
    footerTextColor: theme.footerTextColor,
    lightGray: '#F5F5F5',
    middleGray: '#777777',
    darkGray: '#1F1F1F',
    gray2: '#4F4F4F',
    gray3: '#828282',
    gray4: '#BDBDBD',
    gray5: '#E0E0E0',
    gray6: '#D3D3D3',
    gray7: '#EEEEEE',
    gray8: '#F1F1F1',
    gray9: '#F3F3F3',
    gray10: '#F9F9F9',
    gray11: '#BDBDBD',
    red1: '#E41E1E',
    white: '#FFFFFF',
    black: '#000000',
    orderDeliveryTypeColor: '#D96E18',
    orderPickupTypeColor: '#6CB33F',
    nonProductActiveColor: '#f21b3f',
  };
};
