import addressesSlice from './addresses/addressesSlice';
import branchStoreScheduleSlice from './branchStoreSchedule/branchStoreScheduleSlice';
import branchStoreSlice from './branchStore/branchStoreSlice';
import cartSlice from './cart/cartSlice';
import categoriesSlice from './categories/categoriesSlice';
import { combineReducers } from '@reduxjs/toolkit';
import paymentMethodsSlice from './paymentMethods/paymentMethodsSlice';
import productsSlice from './products/productsSlice';
import uiSlice from './ui/uiSlice';
import userReducer from './user/userSlice';

const rootReducer = combineReducers({
  user: userReducer,
  addresses: addressesSlice,
  cart: cartSlice,
  ui: uiSlice,
  categories: categoriesSlice,
  products: productsSlice,
  paymentMethods: paymentMethodsSlice,
  branchStore: branchStoreSlice,
  branchStoreSchedule: branchStoreScheduleSlice,
});

export default rootReducer;
