import type { PaymentMethodsListType } from '../../types/paymentMethods';
import { createSlice } from '@reduxjs/toolkit';

const initialState: PaymentMethodsListType = {
  count: 0,
  next: null,
  previous: null,
  results: [],
};

const paymentMethodsSlice = createSlice({
  name: 'paymentMethods',
  initialState,
  reducers: {
    setPaymentMethods(_, { payload }) {
      return payload;
    },

    addPaymentMethod(state, { payload }) {
      const paymentMethods = [];

      for (let i = 0; i <= state.results.length - 1; i++) {
        paymentMethods.push({
          ...state.results[i],
          primary: false,
        });
      }
      paymentMethods.push(payload);
      return {
        ...state,
        results: paymentMethods,
      };
    },

    setPrimary(state, { payload }) {
      const paymentMethods = [];

      for (let i = 0; i <= state.results.length - 1; i++) {
        if (state.results[i].id === payload.id) {
          paymentMethods.push({
            ...state.results[i],
            primary: true,
          });
          continue;
        }
        paymentMethods.push({
          ...state.results[i],
          primary: false,
        });
      }
      return {
        ...state,
        results: paymentMethods,
      };
    },

    deletePaymentMethod(state, { payload }) {
      return {
        ...state,
        results: state.results.filter((item) => item.id !== payload.id),
      };
    },
  },
});

export const {
  setPaymentMethods,
  addPaymentMethod,
  setPrimary,
  deletePaymentMethod,
} = paymentMethodsSlice.actions;

export default paymentMethodsSlice.reducer;
