import type { UserAddressListType } from '../../types/address';
import { createSlice } from '@reduxjs/toolkit';

const initialState: UserAddressListType = {
  count: 0,
  next: null,
  previous: null,
  results: [],
};

const addressesSlice = createSlice({
  name: 'addresses',
  initialState,
  reducers: {
    setAddresses(_, { payload }) {
      return payload;
    },

    addAddresses(state, { payload }) {
      const addresses = [];

      for (let i = 0; i <= state.results.length - 1; i++) {
        addresses.push({
          ...state.results[i],
          primary: false,
        });
      }
      addresses.push(payload);
      return {
        ...state,
        results: addresses,
      };
    },

    editedAddresses(state, { payload }) {
      const addresses = [];

      for (let i = 0; i <= state.results.length - 1; i++) {
        if (state.results[i].id === payload.id) {
          addresses.push({
            ...payload,
          });
          continue;
        }
        addresses.push({
          ...state.results[i],
        });
      }
      return {
        ...state,
        results: addresses,
      };
    },

    setPrimary(state, { payload }) {
      const addresses = [];

      for (let i = 0; i <= state.results.length - 1; i++) {
        if (state.results[i].id === payload.id) {
          addresses.push({
            ...state.results[i],
            primary: true,
          });
          continue;
        }
        addresses.push({
          ...state.results[i],
          primary: false,
        });
      }
      return {
        ...state,
        results: addresses,
      };
    },

    deleteUserAddress(state, { payload }) {
      return {
        ...state,
        results: state.results.filter((item) => item.id !== payload.id),
      };
    },
  },
});

export const {
  setAddresses,
  addAddresses,
  editedAddresses,
  setPrimary,
  deleteUserAddress,
} = addressesSlice.actions;

export default addressesSlice.reducer;
