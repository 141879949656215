import 'react-toastify/dist/ReactToastify.min.css';

import type { AppProps } from 'next/app';
import { GlobalStyles } from '../theme/globalStyles';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { ToastContainer } from 'react-toastify';
import { appWithTranslation } from 'next-i18next';
import { initializeStore } from '../store/store';
import { themeConfig } from '../theme/themeConfig';

function MyApp({ Component, pageProps }: AppProps) {

  const theme = themeConfig(pageProps.preloadedState?.ui?.pallette);

  return (
    <Provider store={initializeStore(pageProps.preloadedState)}>
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        <ToastContainer
          position="bottom-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <Component {...pageProps} />
      </ThemeProvider>
    </Provider>
  );
}

export default appWithTranslation(MyApp);
