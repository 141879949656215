import { createGlobalStyle } from 'styled-components';
import { pallette as palletteType } from '../types/theme/pallette';

interface props {
  theme: palletteType,
};

export const GlobalStyles = createGlobalStyle`
  html,
  body {
    padding: 0;
    margin: 0;
    background-color: ${({ theme }: props) => theme.backgroundColor};
    font-family: 'Inter', sans-serif;
    font-size: 16px;
  }

  ::-webkit-scrollbar {
    width: 7px;
    height: 7px;
    background: ${({ theme }: props) => theme.gray3};
  }

  ::-webkit-scrollbar-track {
      background: ${({ theme }: props) => theme.gray5};
      border-radius: 0;
  }

  ::-webkit-scrollbar-thumb {
      border-radius: 15px;
      background: ${({ theme }: props) => theme.gray3};
  } 



  figure, p, h1, h2, h3, h4 {
    margin: 0;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  * {
    box-sizing: border-box;
    outline-color: ${({ theme }: props) => theme.primaryColor};
  }
`;
